<template>
  <div class="pucontainer">
    <div class="topTitle">
      <q-tabs v-model="status">
        <q-tab v-for="(item, index) in tabs" :key="index" :name="item.status" :label="item.title" />
      </q-tabs>
      <div class="search">
        <q-input type="search" dense :label="$t('orders.search_order')" v-model="searchVal">
          <template v-slot:prepend>
            <q-icon name="search" @click="orderSearch" class="cursor-pointer" />
          </template>
          <template v-slot:append>
            <q-icon name="close" @click="searchVal = ''" class="cursor-pointer" />
          </template>
        </q-input>
      </div>
    </div>
    <!-- 我的订单 -->
    <div class="orderlist">
      <div class="ol_item" v-for="(item, index) in list" :key="index">
        <div class="topTab puitem">
          <div>{{ $t("orders.date") }}</div>
          <div>{{ $t("orders.order_number") }}</div>
          <div>{{ $t("orders.shipping_address") }}</div>
          <div>{{ $t("orders.voucher") }}</div>
          <div>{{ $t("orders.s_pay") }}</div>
        </div>
        <div class="con puitem">
          <div>
            <p>{{ item.newdate }}</p>
            <p class="status blod">
              {{
                $i18n.locale == "en"
                ? item.status_contenten
                : item.status_content
              }}
            </p>
          </div>
          <div>{{ item.order_no }}</div>
          <div>
            <p>{{ $t("orders.name") }}{{ item.name }}</p>
            <p>{{ $t("orders.phone") }}{{ item.tel }}</p>
            <p>{{ $t("orders.email") }}{{ item.email }}</p>
            <p>
              {{ $t("orders.address") }}{{ item.province }}{{ item.city
              }}{{ item.area }}{{ item.address }}
            </p>
            <p v-if="item.zcode">
              {{ $t("orders.post_code") }}{{ item.zcode }}
            </p>
            <p class="look-wl" v-if="item.expressname" @click="logistics(item.expressname)">
              {{ $t("orders.look_wl") }}
            </p>
          </div>
          <div class="coupon">
            <template v-if="item.coupon || (item.coupon2 && item.iscoupon2 != '')">
              <p v-if="item.coupon">
                {{ $i18n.locale == "en" ? item.couponen : item.coupon }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount
                }}
              </p>
              <p v-if="item.coupon3">
                {{ $i18n.locale == "en" ? item.coupon3en : item.coupon3 }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount3
                }}
              </p>
              <p v-if="item.coupon2 && item.iscoupon2 != ''">
                {{ $i18n.locale == "en" ? item.coupon2en : item.coupon2 }} -{{ item.channel == 3 ? '￡' : '￥' }}{{
                  item.couponamount2
                }}
              </p>
              <p v-if="item.discountamount !== '0.00'">
                {{ $t("orders.total_discount") }}{{ item.channel == 3 ? '￡' : '￥' }}{{ item.discountamount }}
              </p>
            </template>
            <template v-else>{{ $t("orders.not_used") }}</template>
          </div>
          <div>
            <div :style="item.refundamount != '0.00' || item.refundbalance != '0.00' ? 'color:#999' : ''">
              <p>
                {{ $t("orders.y_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.payableamount }}
              </p>
              <p style="margin-bottom: 15px;" v-if="item.refundamount != '0.00' || item.refundbalance != '0.00'">
                {{ $t('orders.change') }}：{{ item.channel == 3 ? '￡' : '￥' }}{{ item.payableamounta }}
              </p>
              <p v-if="item.coupon2 && item.iscoupon2 == ''">
                {{ $i18n.locale == "en" ? item.coupon2en : item.coupon2 }}
              </p>
              <p>{{ $t("orders.s_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.realamount }}</p>
              <p v-if="item.balances != '0.00' || item.refundbalance != '0.00'">
                {{ $t("orders.b_pay") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.balances }}
              </p>
            </div>
            <p class="liner-dashed" v-if="item.refundamount != '0.00' || item.refundbalance != '0.00'"></p>
            <p v-if="item.refundamount != '0.00'">
              {{ $t("orders.refund") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.refundamount }}
            </p>
            <p v-if="item.refundbalance != '0.00'">
              {{ $t("orders.b_refund") }}:{{ item.channel == 3 ? '￡' : '￥' }}{{ item.refundbalance }}
            </p>
          </div>
        </div>
        <div class="btnBox">
          <div class="button" v-if="item.invoices">
            <q-btn class="full-width" outline color="primary" :label="$t('orders.downInvoice')"
              @click="downInvoice(item)" />
          </div>
          <template v-if="item.source != 2">
            <div class="button" v-if="item.isrefund == 1">
              <q-btn class="full-width" outline color="primary" :label="$t('orders.apply_retrun')"
                @click="apply(item.order_no)" />
            </div>
          </template>
          <div class="button" v-if="item.status == 3">
            <q-btn class="full-width" color="primary" :label="$t('orders.confirm_receipt')" @click="takeOver(item)" />
          </div>
          <div class="button">
            <q-btn class="full-width" color="primary" :label="$t('orders.look_xj')" @click="item.isShow = !item.isShow" />
          </div>
        </div>
        <div class="detailList" v-show="item.isShow">
          <div class="puitem topTab2">
            <div>{{ $t("orders.status") }}</div>
            <div>{{ $t("orders.product") }}</div>
            <div>{{ $t("orders.price") }}</div>
            <div>{{ $t("orders.num") }}</div>
            <div>{{ $t("orders.total") }}</div>
          </div>
          <template v-for="(jtem, j) in item.list" :key="j">
            <div class="con puitem" v-if="jtem.applicationtype != 2 ||
              (jtem.applicationtype == 2 && jtem.order_status == 9)
              ">
              <div class="goods-status">
                <template v-if="jtem.applicationtype != 0">
                  <template v-if="jtem.applicationtype == 1">
                    <template v-if="jtem.order_status == 8">
                      <span></span>
                      <span>{{ $t("orders.return_complete2") }}</span>
                      <span></span>
                    </template>
                    <template v-if="jtem.order_status == 9">
                      <span></span>
                      <span>{{ $t("orders.return_fail2") }}</span>
                      <span>{{ $t("orders.reason") }}:{{
                        $i18n.locale == "en"
                        ? jtem.auditnoteen
                        : jtem.auditnote
                      }}</span>
                    </template>
                    <template v-if="jtem.order_status == 10">
                      <span></span>
                      <span>{{ $t("orders.return_examine2") }}</span>
                      <span>{{ $t("orders.wait_customer_service") }}</span>
                    </template>
                  </template>
                  <template v-if="jtem.applicationtype == 2">
                    <template v-if="jtem.order_status == 8">
                      <span></span>
                      <span>{{ $t("orders.exchange_complete") }}</span>
                      <span></span>
                    </template>
                    <template v-if="jtem.order_status == 9">
                      <span></span>
                      <span>{{ $t("orders.exchange_fail") }}</span>
                      <span>{{ $t("orders.reason") }}:{{
                        $i18n.locale == "en"
                        ? jtem.auditnoteen
                        : jtem.auditnote
                      }}</span>
                    </template>
                    <template v-if="jtem.order_status == 10">
                      <span></span>
                      <span>{{ $t("orders.exchange_examine") }}</span>
                      <span>{{ $t("orders.wait_customer_service") }}</span>
                    </template>
                  </template>
                  <template v-if="jtem.applicationtype == 3">
                    <template v-if="jtem.order_status == 8">
                      <span></span>
                      <span>{{ $t("orders.return_complete") }}</span>
                      <span></span>
                    </template>
                    <template v-if="jtem.order_status == 9">
                      <span></span>
                      <span>{{ $t("orders.return_fail") }}</span>
                      <span>{{ $t("orders.reason") }}:{{
                        $i18n.locale == "en"
                        ? jtem.auditnoteen
                        : jtem.auditnote
                      }}</span>
                    </template>
                    <template v-if="jtem.order_status == 10">
                      <span></span>
                      <span>{{ $t("orders.return_examine") }}</span>
                      <span>{{ $t("orders.wait_customer_service") }}</span>
                    </template>
                  </template>
                </template>
                <template v-if="jtem.applicationtype == 0">
                  <template v-if="jtem.order_status == 6">
                    <span></span>
                    <span>{{ $t("orders.exchange_goods") }}</span>
                    <span></span>
                  </template>
                  <template v-else-if="jtem.order_status == 8">
                    <span></span>
                    <span>{{ $t("orders.exchange_complete") }}</span>
                    <span></span>
                  </template>
                  <template v-else-if="jtem.order_status == 10">
                    <span></span>
                    <span>{{ $t("orders.exchange_examine") }}</span>
                    <span>{{ $t("orders.wait_customer_service") }}</span>
                  </template>
                  <template v-else>
                    <span></span>
                    <span>{{ $t("orders.normal_goods") }}</span>
                    <span></span>
                  </template>
                </template>
              </div>
              <div class="goodsdiv">
                <div class="left">
                  <template v-if="(jtem.sprices == '0.00' && jtem.stock == 0) || item.source == 2">
                    <q-img :src="jtem.photo" alt :ratio="6 / 8" @click="$q.notify($t('common.no_goods'))" />
                  </template>
                  <template v-else>
                    <div @click="goodsToUrl(jtem)">
                      <q-img :src="jtem.photo" alt :ratio="6 / 8" />
                    </div>
                  </template>
                </div>
                <div class="right">
                  <p class="brand blod">{{ jtem.brand }}</p>
                  <p class="name">
                    {{ $i18n.locale == "en" ? jtem.titleen : jtem.title }}
                  </p>
                  <p class="color">
                    {{ $t("orders.color")
                    }}{{ $i18n.locale == "en" ? jtem.coloren : jtem.color }}
                  </p>
                  <p class="size" v-if="jtem.size != '01'">
                    {{ $t("orders.size") }}{{ jtem.size }}
                  </p>
                  <p class="code">{{ $t("orders.g_code") }}{{ jtem.code }}</p>
                  <p class="look-goods" v-if="jtem.applicationtype == 0 &&
                    (jtem.order_status == 8 ||
                      jtem.order_status == 6 ||
                      jtem.order_status == 10)
                    " @click="exchangeClick(item, jtem)">
                    {{ $t("orders.look_before_exchange") }}
                  </p>
                </div>
              </div>
              <div>
                <p class="blod line-through" v-if="jtem.isdiscount == 1">
                  {{ item.channel == 3 ? '￡' : '￥' }}{{ jtem.oprice }}
                </p>
                <p class="blod">
                  {{ item.channel == 3 ? '￡' : '￥' }}{{ jtem.sprice }}
                  <span v-if="jtem.coupon || jtem.coupon3">
                    {{ getPrenct(jtem) }}
                  </span>
                </p>
              </div>
              <div>
                <p>{{ jtem.quantity }}</p>
              </div>
              <div>
                <p class="blod">{{ item.channel == 3 ? '￡' : '￥' }}{{ jtem.realamount }}</p>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <m-empty v-if="!list.length"></m-empty>
    <!-- 分页 -->
    <div class="page" v-if="page != 0">
      <q-pagination v-model="pageindex" :max="page" direction-links :max-pages="7" />
    </div>
    <show-return :exchangeShow="exchangeShow" :exchangeGoodsInfo="exchangeGoodsInfo" @hideHandle="exchangeShow = false">
    </show-return>
  </div>
</template>

<script>
import mEmpty from '@/components/Empty.vue'
import { getOrder } from '@/api/my'
import { payApi } from '@/api/goods'
import { toexpressname } from '@/plugins/logistics'
import showReturn from '@/components/lookReturnGoods'
import { getPrenct } from '@/utils/filterData'
import { goodsToUrl } from '@/utils/goodsToUrl'

export default {
  components: {
    mEmpty,
    showReturn
  },
  data() {
    return {
      tabs: [
        { title: this.$t('orders.all'), status: 0 },
        { title: this.$t('orders.pending'), status: 2 },
        { title: this.$t('orders.shipped'), status: 3 },
        { title: this.$t('orders.completed'), status: 4 }
      ],
      list: [],
      searchVal: '',
      status: 0,
      pageindex: 1,
      pagesize: 5,
      page: 0,
      exchangeShow: false,
      exchangeGoodsInfo: {},
      searchTimer: null
    }
  },
  watch: {
    status() {
      this.searchVal = ''
      this.getList()
    },
    pageindex() {
      this.getList()
      window.scrollTo(0, 0)
    },
    searchVal() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(() => {
        this.getList()
      }, 400)
    }
  },
  methods: {
    async getList() {
      const res = await getOrder({
        type: 0,
        status: this.status,
        pageindex: this.pageindex,
        pagesize: this.pagesize,
        where: this.searchVal
      })
      if (res.status === 1) {
        if (this.pageindex === 1) {
          this.page = Math.ceil(res.totalCount / this.pagesize)
        }
        this.list = res.list
      }
    },
    // 确定收货
    takeOver(item) {
      this.$q
        .dialog({
          message: `${this.$t('orders.confirm_receipt')}?`,
          cancel: true
        })
        .onOk(async () => {
          const res = await payApi({
            type: 4,
            mark: item.oguid
          })
          if (res.status === 1) {
            this.$q.notify(this.$t('orders.goods_received_successfully'))
            item.status = 4
            item.status_content = this.$t('orders.successful_trade')
          }
        })
    },
    // 申请退货
    apply(id) {
      this.$router.push('/my/ReturnGoods?id=' + id)
    },
    // 订单搜索
    orderSearch() {
      this.pageindex = 1
      this.getList()
    },
    // 查看换货商品弹框
    exchangeClick(item, jtem) {
      const goods = item.list.find((val) => {
        if (val.applicationtype === '2') {
          return val.cguid === jtem.cguid
        }
      })
      this.exchangeGoodsInfo = goods
      this.exchangeShow = true
    },
    // 查看物流跳转
    logistics(id) {
      toexpressname(id)
    },
    // 下载发票
    async downInvoice(item) {
      const res = await getOrder({
        type: 168,
        mark: item.oguid
      })
      if (res.status === 1) {
        window.open(res.invoices)
      }
    }
  },
  mounted() {
    this.getList()
  },
  setup() {
    return {
      getPrenct,
      goodsToUrl
    }
  }
}
</script>

<style lang="scss" scoped>
@import './orderScss.scss';

.topTitle {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  margin-top: 20px;

  .search {
    width: 200px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
}

.orderlist {
  .ol_item {
    .puitem {
      &>div:last-child {
        max-width: 180px;
      }
    }

    .detailList {
      .goodsdiv {
        width: 470px;
        max-width: 470px;
      }
    }
  }
}
</style>
